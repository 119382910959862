<template>
  <BCard>
    <b-row class="justify-content-between mt-1">
      <b-col class="col-md-2">
        <BFormGroup label="Поиск">
          <BFormInput
            v-model="filter.search"
            type="search"
            @input="searchCon"
          />
        </BFormGroup>
      </b-col>
      <b-col class="col-md-3 text-right">
        <BButton
          style="margin-top: 27px"
          variant="primary"
          @click="openModal()"
        >
          <feather-icon
            icon="PlusIcon"
          />
          Добавить
        </BButton>
      </b-col>
    </b-row>

    <BTable
      id="user-table"
      :busy="loading"
      class="bg-light-secondary px-1 rounded mt-1 my-2"
      :tbody-tr-class="['tableRowClass']"
      :items="roles"
      :fields="fields"
      hover
      small
      responsive
      show-empty
    >
      <template #empty>
        <empty />
      </template>
      <template #table-busy>
        <div class="d-flex align-items-center flex-column p-3">
          <b-spinner
            variant="primary"
          />
          <span>Загрузка...</span>
        </div>
      </template>
      <template #cell(unique_name)="props">
        <span v-if="props.item.unique_name === 'admin'">Админ</span>
        <span v-else-if="props.item.unique_name === 'employee'">Пользователь</span>
        <span v-else-if="props.item.unique_name === 'dk_manager'">Добрый коллектор менеджер</span>
        <span v-else-if="props.item.unique_name === 'dk_operator'">Добрый коллектор оператор</span>
        <span v-else>Пользователь загрузки реестров</span>
      </template>
      <template #cell(actions)="props">
        <div class="d-flex align-items-center justify-content-center">
          <BButton
            size="sm"
            class="mr-1"
            variant="warning"
            @click="editModal(props.item)"
          >
            <feather-icon icon="EditIcon" />
          </BButton>
          <BButton
            size="sm"
            variant="danger"
            @click="deleteData(props.item)"
          >
            <feather-icon icon="TrashIcon" />
          </BButton>
        </div>
      </template>
    </BTable>
    <TheAddEditRoleVue
      ref="addEdit"
      :edit-mod="editMod"
      @refresh="fetchActionList"
    />
  </BCard>
</template>

<script>
import {
  BCard,
  BButton,
  BTable,
  BFormGroup,
  BFormInput,
  BSpinner,
  BRow,
  BCol,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import empty from '@/components/empty.vue'
import TheAddEditRoleVue from '../components/TheAddEditRole.vue'

export default {
  components: {
    BCard,
    BButton,
    BTable,
    BFormGroup,
    BFormInput,
    TheAddEditRoleVue,
    BSpinner,
    BRow,
    BCol,
    empty,
  },
  data() {
    return {
      loading: false,
      filter: {
        search: null,
      },
      editMod: {},
      count: 2,
      fields: [
        {
          key: 'id',
          label: '№',
          sortable: true,
          thClass: 'tableHeadClass',
        },
        {
          key: 'name',
          label: 'Название',
          sortable: true,
          thClass: 'tableHeadClass',
        },
        {
          key: 'unique_name',
          label: 'Разрешения',
          sortable: true,
          thClass: 'tableHeadClass',
        },
        {
          key: 'actions',
          label: 'Действия',
          sortable: false,
          thClass: 'tableHeadClass',
        },
      ],
    }
  },
  computed: {
    ...mapState('roles', ['roles']),
  },
  mounted() {
    this.fetchActionList()
  },
  methods: {
    ...mapActions('roles', ['FETCH_ALL_ROLES', 'DELETE_ROLE']),

    searchCon() {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.FETCH_ALL_ROLES(this.filter)
      }, 200)
    },

    async fetchActionList() {
      try {
        this.loading = true
        await this.FETCH_ALL_ROLES(this.filter)
      } catch (error) {
        await this.$_errorToast()
      }
      this.loading = false
    },
    deleteData(item) {
      const req = {
        id: item.id,
      }
      this.$bvModal
        .msgBoxConfirm('Вы действительно хотите удалить эту запись?', {
          title: 'Удалить',
          okVariant: 'danger',
          okTitle: 'Подтвердить',
          cancelTitle: 'Отменить',
          hideHeaderClose: false,
          centered: true,
        })
        .then(success => {
          if (success) {
            this.DELETE_ROLE(req)
              .then(() => {
                this.fetchActionList()
                this.$_okToast()
              })
          }
        })
    },

    editModal(item) {
      this.editMod = item
      this.$nextTick(() => {
        this.$bvModal.show('modal')
      })
    },

    openModal() {
      this.editMod = {}
      this.$nextTick(() => {
        this.$bvModal.show('modal')
      })
    },
  },
}
</script>
