<template>
  <div>
    <BModal
      id="modal"
      size="md"
      centered
      :title="title"
      :ok-variant="variant"
      :ok-title="btnTitle"
      cancel-title="Отменить"
      responsive
      no-close-on-backdrop
      @show="shodModal"
      @ok.prevent="submitHandler"
    >
      <ValidationObserver ref="roleValidate">
        <BFormGroup
          label="Название"
        >
          <ValidationProvider
            #default="{ errors }"
            name="Название"
            rules="required"
          >
            <BFormInput
              v-model="formData.name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </BFormGroup>

        <BFormGroup label="Разрешения">
          <ValidationProvider
            #default="{ errors }"
            name="Разрешения"
            rules="required"
          >
            <v-select
              v-model="formData.unique_name"
              label="title"
              :reduce="p => p.value"
              :options="PERMISSIONS"
            >
              <template #no-options>
                К сожалению, нет подходящих вариантов
              </template>
            </v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>

        </BFormGroup>
      </ValidationObserver>
    </BModal>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import { mapState, mapActions } from 'vuex'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    BFormGroup,
    BFormInput,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    editMod: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      required,
      PERMISSIONS: [
        {
          title: 'Админ',
          value: 'admin',
        },
        {
          title: 'Пользователь',
          value: 'employee',
        },
        {
          title: 'Пользователь загрузки реестров',
          value: 'rester_employee',
        },
        {
          title: 'Добрый коллектор менеджер',
          value: 'dk_manager',
        },
        {
          title: 'Добрый коллектор оператор',
          value: 'dk_operator',
        },
      ],
      formData: {
        name: '',
        unique_name: '',
      },
    }
  },
  computed: {
    ...mapState('roles', ['permissions', 'role']),

    title() {
      return this.editMod.id ? 'Изменить' : 'Добавить'
    },

    btnTitle() {
      return this.editMod.id ? 'Редактировать' : 'Сохранить'
    },

    variant() {
      return this.editMod.id ? 'warning' : 'primary'
    },
  },
  methods: {
    ...mapActions('roles', ['FETCH_ALL_PERMISSIONS', 'FETCH_ROLE', 'CREATE_ROLE', 'PATCH_ROLE']),

    async submitHandler() {
      const isValid = await this.$refs.roleValidate.validate()

      if (!isValid) return

      try {
        if (this.editMod?.id) {
          await this.PATCH_ROLE({
            id: this.editMod.id,
            ...this.formData,
          })
          this.$emit('refresh')
        } else {
          await this.CREATE_ROLE(this.formData)
          this.$emit('refresh')
        }
        await this.$_okToast()
        this.$bvModal.hide('modal')
      } catch (e) {
        await this.$_errorToast(e)
      }
    },

    shodModal() {
      this.formData = JSON.parse(JSON.stringify(this.editMod))
    },
  },
}
</script>
